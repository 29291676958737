import styled from "styled-components";
import { FaFutbol, FaHandHoldingHeart, FaSquare } from "react-icons/fa"; // Ícones para gol, assistência e cartão

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: url('/background_app.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
`;

export const StyledCard = styled.div`
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 90%;
  margin-top: 20px;
`;

export const JogoCard = styled.div`
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  .team {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .placar {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  .jogadores-info {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
  }

  .gols, .cartoes, .assistencias {
    flex: 1;
    margin-right: 10px;
  }
`;

export const TeamImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

export const CartaoIcon = styled(FaSquare)`
  color: ${(props) =>
    props.tipo === "amarelo"
      ? "yellow"
      : props.tipo === "vermelho"
      ? "red"
      : "blue"};
  margin-right: 5px;
`;

export const BallIcon = styled(FaFutbol)`
  color: black;
  margin-right: 5px;
`;

export const AssistIcon = styled(FaHandHoldingHeart)`
  color: green;
  margin-right: 5px;
`;

export const StyledButton = styled.button`
  background-color: #1976d2;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    background-color: #115293;
  }
`;
