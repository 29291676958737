import React, { useState, useEffect } from "react";
import { PageContainer, StyledCard, StyledButton, JogoCard, TeamImage, CartaoIcon, BallIcon, AssistIcon } from "./Style";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Card, CardContent, MenuItem, TextField, InputLabel, Select, FormControl } from "@mui/material";
import styled from "styled-components";
import { ArrowBack } from "@mui/icons-material";



const BackButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const ListarJogosPage = () => {
  const navigate = useNavigate();
  const [jogos, setJogos] = useState([]);

  useEffect(() => {
    const fetchJogos = async () => {
      try {
        const response = await api.get("/jogos");
        setJogos(response.data.jogos);
      } catch (error) {
        console.error("Erro ao buscar jogos:", error);
      }
    };
    fetchJogos();
  }, []);

  const handleEditJogo = (jogoId) => {
    navigate(`/editar-jogo/${jogoId}`);
  };

  const handleViewDetails = (jogoId) => {
    // Navega para a página de detalhes do jogo (EditarJogoDetalhes)
    navigate(`/jogos/${jogoId}/detalhes`);
  };

  
  return (
    <PageContainer>
    <BackButton onClick={() => navigate("/torneio")} startIcon={<ArrowBack />}>
    Voltar
  </BackButton>
      <StyledCard>
        <h2>Lista de Jogos</h2>
        <div className="jogos-container">
          {jogos.map((jogo) => (
            <>
            
            <JogoCard key={jogo._id}  onClick={() => handleViewDetails(jogo._id)}>
              <div className="team">
                <div className="team-info">
                  <TeamImage
                    src={`/times/${jogo.timeA.nome.replace(/ /g, "_")}.png`}
                    alt={`${jogo.timeA.nome}`}
                  />
                 <div>
                  <strong>{jogo.timeA.nome}</strong>

                  </div>
                 <div>(G){jogo.goleiroTimeA}</div>
                </div>

                {/* Centralizar o placar */}
                <div className="placar">
                  <strong>{jogo.placar.timeA} x {jogo.placar.timeB}</strong>
                </div>

                <div className="team-info">
                  <TeamImage
                    src={`/times/${jogo.timeB.nome.replace(/ /g, "_")}.png`}
                    alt={`${jogo.timeB.nome}`}
                  />
                  <div>
                  <strong>{jogo.timeB.nome}</strong>

                  </div>
                  <div>(G){jogo.goleiroTimeB}</div>
                </div>
              </div>

              <div className="jogadores-info">
                <div className="gols">
                  <strong>Gols:</strong>
                  {jogo.gols.map((gol) => (
                    <div key={gol.jogador._id}>
                      <BallIcon /> {gol.jogador.nome}: {gol.quantidade} gol(s)
                    </div>
                  ))}
                </div>

                <div className="cartoes">
                  <strong>Cartões:</strong>
                  {jogo.cartoes.map((cartao) => (
                    <div key={cartao.jogador._id}>
                      <CartaoIcon tipo={cartao.tipo} /> {cartao.jogador.nome} - {cartao.tipo.charAt(0).toUpperCase() + cartao.tipo.slice(1)}
                    </div>
                  ))}
                </div>

                <div className="assistencias">
                  <strong>Assistências:</strong>
                  {jogo.assistencias.map((assistencia) => (
                    <div key={assistencia.jogador._id}>
                      <AssistIcon /> {assistencia.jogador.nome}: {assistencia.quantidade} assistência(s)
                    </div>
                  ))}
                </div>
              </div>

            </JogoCard>
              <StyledButton onClick={() => handleEditJogo(jogo._id)}>
                Editar Jogo
              </StyledButton>
            </>
          ))}
        </div>
      </StyledCard>
    </PageContainer>
  );
};

export default ListarJogosPage;
