import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import {
  PageContainer,
  StyledCard,
  StyledButton,
  PlayerList,
  PlayerImage,
  DialogWrapper,
  DialogActions,
  DialogButton,
  DialogTitle,
  BackButton,
  CardButton,
} from "./StyledJogoDetalhes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "../../api";

const EditarJogoDetalhes = () => {
  const { jogoId } = useParams();
  const navigate = useNavigate();
  const [jogo, setJogo] = useState(null);
  const [times, setTimes] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null); // 'gol', 'assistencia', 'cartao'
  const [selectedCard, setSelectedCard] = useState(null); // 'amarelo', 'azul', 'vermelho'
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jogoResponse = await api.get(`/jogos/${jogoId}`);
        const jogoData = jogoResponse.data.jogo;
        setJogo(jogoData);

        const timesResponse = await api.post("/report/getTimes", {
          torneioId: jogoData.torneio,
        });

        const timesRelacionados = {};
        Object.keys(timesResponse.data).forEach((timeName) => {
          if (
            timeName === jogoData.timeA.nome ||
            timeName === jogoData.timeB.nome
          ) {
            timesRelacionados[timeName] = timesResponse.data[timeName];
          }
        });

        setTimes(timesRelacionados);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, [jogoId]);

  const openDialog = (action) => {
    setSelectedAction(action);
    setDialogOpen(true);
  };

  const handleSave = async () => {
    if (!selectedPlayer || (selectedAction === "cartao" && !selectedCard)) {
      setSnackbarMessage("Por favor, selecione o jogador e a cor do cartão.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    let dataToSave = {
      jogoId,
    };

    if (selectedAction === "cartao") {
      dataToSave = {
        jogoId,
        cartoes: {
          jogador: selectedPlayer._id,
          tipo: selectedCard,
        },
      };
    } else if (selectedAction === "gol") {
      dataToSave = {
        jogoId,
        gols: {
          jogador: selectedPlayer._id,
          quantidade: 1,
        },
      };
    } else if (selectedAction === "assistencia") {
      dataToSave = {
        jogoId,
        assistencias: {
          jogador: selectedPlayer._id,
          quantidade: 1,
        },
      };
    }

    try {
      await api.put("/jogos", dataToSave);
      setSnackbarMessage("Informações salvas com sucesso!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      navigate("/listar-jogos");
    } catch (error) {
      setSnackbarMessage("Erro ao salvar informações.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Erro ao salvar:", error);
    }
  };

  const renderPlayers = (team) => {
    return team.jogadores.map((jogador) => (
      <div key={jogador._id} onClick={() => setSelectedPlayer(jogador)}>
        <PlayerImage
          src={
            jogador.nome === "THIAGO NUNES"
              ? `/players/${jogador.nome.replace(/ /g, "_")}.jpg`
              : `/players/${jogador.nome.split(" ")[0].replace(/ /g, "_")}.jpg`
          }
          alt={jogador.nome}
        />
        <span>{jogador.nome}</span>
        <span>{jogador.numeroCamisa}</span>
      </div>
    ));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (!jogo) return <div>Carregando...</div>;

  return (
    <PageContainer>
      <BackButton onClick={() => navigate("/listar-jogos")}>
        <ArrowBackIcon /> Voltar
      </BackButton>
      <StyledCard>
        <h2>Detalhes do Jogo</h2>
        <div>
          <strong>{jogo.timeA.nome}</strong> vs <strong>{jogo.timeB.nome}</strong>
        </div>
        <StyledButton onClick={() => openDialog("gol")}>Adicionar Gol</StyledButton>
        <StyledButton onClick={() => openDialog("assistencia")}>
          Adicionar Assistência
        </StyledButton>
        <StyledButton onClick={() => openDialog("cartao")}>Adicionar Cartão</StyledButton>
      </StyledCard>

      <DialogWrapper open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Selecione a Ação: {selectedAction}</DialogTitle>
        {selectedAction === "cartao" && (
          <div>
            <DialogButton
              style={{ backgroundColor: "yellow", color: "black" }}
              onClick={() => setSelectedCard("amarelo")}
            >
              Cartão Amarelo
            </DialogButton>
            <DialogButton
              style={{ backgroundColor: "blue", color: "white" }}
              onClick={() => setSelectedCard("azul")}
            >
              Cartão Azul
            </DialogButton>
            <DialogButton
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => setSelectedCard("vermelho")}
            >
              Cartão Vermelho
            </DialogButton>
          </div>
        )}

        <StyledCard>
          <strong>{jogo.timeA.nome}</strong> vs{" "}
          <strong>{jogo.timeB.nome}</strong>
        </StyledCard>
        <StyledCard>
          <strong>Selecionado:</strong>
        </StyledCard>
        <StyledCard>
          <strong>{selectedAction}</strong>
          <br />
          {selectedPlayer !== null && (
            <>
              <PlayerImage
                src={
                  selectedPlayer.nome === "THIAGO NUNES"
                    ? `/players/${selectedPlayer.nome.replace(/ /g, "_")}.jpg`
                    : `/players/${selectedPlayer.nome
                        .split(" ")[0]
                        .replace(/ /g, "_")}.jpg`
                }
                alt={selectedPlayer.nome}
              />
              <span>{selectedPlayer.nome}</span>
              <br />
            </>
          )}
          {selectedCard !== null && <>{selectedCard}</>}
        </StyledCard>
        <PlayerList>{Object.values(times).map((team) => renderPlayers(team))}</PlayerList>
        <DialogActions>
          <DialogButton onClick={handleSave}>Salvar</DialogButton>
        </DialogActions>
      </DialogWrapper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default EditarJogoDetalhes;
