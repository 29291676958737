import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import styled from "styled-components";
import { ArrowBack } from "@mui/icons-material";

// Estilos personalizados
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const BackButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const TeamImage = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 10px;
`;

const EditarJogoPage = () => {
  const { jogoId } = useParams();
  const navigate = useNavigate();
  const [jogo, setJogo] = useState(null);
  const [times, setTimes] = useState([]);
  const [goleiros, setGoleiros] = useState([]);
  const [torneioId, setTorneioId] = useState(null);
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const [goleiroTime1, setGoleiroTime1] = useState("");
  const [goleiroTime2, setGoleiroTime2] = useState("");
  const [dataJogo, setDataJogo] = useState("");
  const [placarTime1, setPlacarTime1] = useState(0);
  const [placarTime2, setPlacarTime2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const torneioResponse = await api.get("/getTorneioActive");
        const torneio = torneioResponse.data;
        const timesResponse = await api.get("/times");

        setTimes(timesResponse.data.times);
        setGoleiros(torneio.goleiros);
        setTorneioId(torneio._id);

        const jogoResponse = await api.get(`/jogos/${jogoId}`);
        const jogoData = jogoResponse.data.jogo;
        setJogo(jogoData);
        setTime1(jogoData.timeA._id);
        setTime2(jogoData.timeB._id);
        setGoleiroTime1(jogoData.goleiroTimeA);
        setGoleiroTime2(jogoData.goleiroTimeB);
        setDataJogo(jogoData.dataJogo);
        setPlacarTime1(jogoData.placar.timeA);
        setPlacarTime2(jogoData.placar.timeB);
      } catch (error) {
        setErrorMessage("Erro ao buscar dados do jogo");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [jogoId]);

  const handleSubmit = async () => {
    const jogoEditado = {
      timeA: time1,
      timeB: time2,
      goleiroTime1,
      goleiroTime2,
      dataJogo,
      placar: { timeA: placarTime1, timeB: placarTime2 },
      jogoId: jogoId,
    };

    try {
      setLoading(true);
      await api.put(`/jogos`, jogoEditado);
      setSuccessMessage("Jogo atualizado com sucesso!");
      navigate("/listar-jogos");
    } catch (error) {
      setErrorMessage("Erro ao editar o jogo");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (type, jogadorId) => {
    try {
      setLoading(true);
      let endpoint = "";
      if (type === "gol") {
        endpoint = "/deleteGol";
      } else if (type === "assistencia") {
        endpoint = "/deleteAssistencia";
      } else if (type === "cartao") {
        endpoint = "/deleteCartao";
      }

      await api.post(endpoint, { jogoId, jogadorId });
      setSuccessMessage(
        `${type.charAt(0).toUpperCase() + type.slice(1)} removido com sucesso!`
      );
      window.location.reload();
    } catch (error) {
      setErrorMessage(`Erro ao remover ${type}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/listar-jogos");
  };

  if (loading) return <CircularProgress />;

  return (
    <PageContainer>
      <BackButton onClick={handleBack} startIcon={<ArrowBack />}>
        Voltar
      </BackButton>
      <Card style={{ width: "100%", maxWidth: 800 }}>
        <CardContent>
          <h2 style={{ textAlign: "center" }}>Editar Jogo</h2>
          <form>
            <Grid container spacing={3}>
              {/* Seleção do Time 1 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time 1</InputLabel>
                  <Select
                    value={time1}
                    onChange={(e) => setTime1(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione o Time 1</em>
                    </MenuItem>
                    {times.map((time) => (
                      <MenuItem key={time._id} value={time._id}>
                        <TeamImage
                          src={`/times/${time.nome.replace(/ /g, "_")}.png`}
                          alt={time.nome}
                        />
                        {time.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Seleção do Goleiro Time 1 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Goleiro Time 1</InputLabel>
                  <Select
                    value={goleiroTime1}
                    onChange={(e) => setGoleiroTime1(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Selecione o Goleiro</em>
                    </MenuItem>
                    {goleiros.map((goleiro) => (
                      <MenuItem key={goleiro} value={goleiro}>
                        {goleiro}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Seleção do Time 2 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time 2</InputLabel>
                  <Select
                    value={time2}
                    onChange={(e) => setTime2(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione o Time 2</em>
                    </MenuItem>
                    {times.map((time) => (
                      <MenuItem key={time._id} value={time._id}>
                        <TeamImage
                          src={`/times/${time.nome.replace(/ /g, "_")}.png`}
                          alt={time.nome}
                        />
                        {time.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Seleção do Goleiro Time 2 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Goleiro Time 2</InputLabel>
                  <Select
                    value={goleiroTime2}
                    onChange={(e) => setGoleiroTime2(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Selecione o Goleiro</em>
                    </MenuItem>
                    {goleiros.map((goleiro) => (
                      <MenuItem key={goleiro} value={goleiro}>
                        {goleiro}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Data e hora do jogo */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Data do Jogo"
                  type="datetime-local"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    dataJogo
                      ? new Date(dataJogo).toISOString().substring(0, 16)
                      : ""
                  }
                  onChange={(e) => setDataJogo(e.target.value)}
                />
              </Grid>

              {/* Placar Time 1 */}
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Placar Time 1"
                  type="number"
                  fullWidth
                  value={placarTime1}
                  onChange={(e) => setPlacarTime1(Number(e.target.value))}
                />
              </Grid>

              {/* Placar Time 2 */}
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Placar Time 2"
                  type="number"
                  fullWidth
                  value={placarTime2}
                  onChange={(e) => setPlacarTime2(Number(e.target.value))}
                />
              </Grid>

              {/* Botão de Salvar */}
              <Grid item xs={12}>
                <StyledButton onClick={handleSubmit}>
                  Salvar Alterações
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      {/* Exibir Gols */}
      <Card style={{ width: "100%", maxWidth: 800, marginTop: 20 }}>
        <CardContent>
          <h3>Gols</h3>
          {jogo?.gols.length > 0 ? (
            jogo.gols.map((gol) => (
              <div
                key={gol.jogador._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  {gol.jogador.nome} - {gol.quantidade} gol(s)
                </span>
                <Button
                  onClick={() => handleDelete("gol", gol.jogador._id)}
                  color="secondary"
                >
                  Excluir
                </Button>
              </div>
            ))
          ) : (
            <p>Nenhum gol registrado.</p>
          )}
        </CardContent>
      </Card>

      {/* Exibir Assistências */}
      <Card style={{ width: "100%", maxWidth: 800, marginTop: 20 }}>
        <CardContent>
          <h3>Assistências</h3>
          {jogo?.assistencias.length > 0 ? (
            jogo.assistencias.map((assistencia) => (
              <div
                key={assistencia.jogador._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  {assistencia.jogador.nome} - {assistencia.quantidade}{" "}
                  assistência(s)
                </span>
                <Button
                  onClick={() =>
                    handleDelete("assistencia", assistencia.jogador._id)
                  }
                  color="secondary"
                >
                  Excluir
                </Button>
              </div>
            ))
          ) : (
            <p>Nenhuma assistência registrada.</p>
          )}
        </CardContent>
      </Card>

      {/* Exibir Cartões */}
      <Card style={{ width: "100%", maxWidth: 800, marginTop: 20 }}>
        <CardContent>
          <h3>Cartões</h3>
          {jogo?.cartoes.length > 0 ? (
            jogo.cartoes.map((cartao) => (
              <div
                key={cartao.jogador._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  {cartao.jogador.nome} - Cartão {cartao.tipo}
                </span>
                <Button
                  onClick={() => handleDelete("cartao", cartao.jogador._id)}
                  color="secondary"
                >
                  Excluir
                </Button>
              </div>
            ))
          ) : (
            <p>Nenhum cartão registrado.</p>
          )}
        </CardContent>
      </Card>

      {/* Snackbar de Sucesso */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar de Erro */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default EditarJogoPage;
