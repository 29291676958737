import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import styled from "styled-components";

// Estilos personalizados
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const BackButton = styled(Button)`
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const TeamImage = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 10px;
`;

const CadastrarJogoPage = () => {
  const navigate = useNavigate();
  const [times, setTimes] = useState([]);
  const [goleiros, setGoleiros] = useState([]);
  const [torneioId, setTorneioId] = useState(null);
  const [torneioNome, setTorneioNome] = useState("");
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const [goleiroTime1, setGoleiroTime1] = useState("");
  const [goleiroTime2, setGoleiroTime2] = useState("");
  const [dataJogo, setDataJogo] = useState("");
  const [placarTime1, setPlacarTime1] = useState(0);
  const [placarTime2, setPlacarTime2] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchTorneioData = async () => {
      try {
        const torneioResponse = await api.get("/getTorneioActive");
        const torneio = torneioResponse.data;
        const timesResponse = await api.get("/times");

        setGoleiros(torneio.goleiros);
        setTorneioId(torneio._id);
        setTorneioNome(torneio.nome);
        setTimes(timesResponse.data.times);
      } catch (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage("Erro ao buscar dados do torneio.");
        setSnackbarOpen(true);
      }
    };
    fetchTorneioData();
  }, []);

  const handleSubmit = async () => {
    const novoJogo = {
      time1,
      time2,
      goleiroTime1,
      goleiroTime2,
      dataJogo,
      placarTime1,
      placarTime2,
      torneioId,
    };

    try {
      await api.post("/cadastro-jogos", novoJogo);
      setSnackbarSeverity("success");
      setSnackbarMessage("Jogo cadastrado com sucesso!");
      setSnackbarOpen(true);
      navigate("/listar-jogos");
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Erro ao cadastrar o jogo.");
      setSnackbarOpen(true);
    }
  };

  const handleBack = () => {
    navigate("/torneio");
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <BackButton onClick={handleBack} startIcon={<ArrowBack />}>
          Voltar
        </BackButton>
        <h2>{torneioNome}</h2>
      </HeaderContainer>

      <Card style={{ width: "100%", maxWidth: 800 }}>
        <CardContent>
          <h2 style={{ textAlign: "center" }}>Cadastrar Novo Jogo</h2>
          <form>
            <Grid container spacing={3}>
              {/* Seleção do Time 1 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time 1</InputLabel>
                  <Select
                    value={time1}
                    onChange={(e) => setTime1(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione o Time 1</em>
                    </MenuItem>
                    {times.map((time) => (
                      <MenuItem key={time._id} value={time._id}>
                        <TeamImage
                          src={`/times/${time.nome.replace(/ /g, "_")}.png`}
                          alt={time.nome}
                        />
                        {time.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Seleção do Goleiro Time 1 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Goleiro Time 1</InputLabel>
                  <Select
                    value={goleiroTime1}
                    onChange={(e) => setGoleiroTime1(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Selecione o Goleiro</em>
                    </MenuItem>
                    {goleiros.map((goleiro) => (
                      <MenuItem key={goleiro} value={goleiro}>
                        {goleiro}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Seleção do Time 2 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Time 2</InputLabel>
                  <Select
                    value={time2}
                    onChange={(e) => setTime2(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione o Time 2</em>
                    </MenuItem>
                    {times.map((time) => (
                      <MenuItem key={time._id} value={time._id}>
                        <TeamImage
                          src={`/times/${time.nome.replace(/ /g, "_")}.png`}
                          alt={time.nome}
                        />
                        {time.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Seleção do Goleiro Time 2 */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Goleiro Time 2</InputLabel>
                  <Select
                    value={goleiroTime2}
                    onChange={(e) => setGoleiroTime2(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Selecione o Goleiro</em>
                    </MenuItem>
                    {goleiros.map((goleiro) => (
                      <MenuItem key={goleiro} value={goleiro}>
                        {goleiro}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Data e hora do jogo */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Data do Jogo"
                  type="datetime-local"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataJogo}
                  onChange={(e) => setDataJogo(e.target.value)}
                />
              </Grid>

              {/* Placar Time 1 */}
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Placar Time 1"
                  type="number"
                  fullWidth
                  value={placarTime1}
                  onChange={(e) => setPlacarTime1(Number(e.target.value))}
                />
              </Grid>

              {/* Placar Time 2 */}
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Placar Time 2"
                  type="number"
                  fullWidth
                  value={placarTime2}
                  onChange={(e) => setPlacarTime2(Number(e.target.value))}
                />
              </Grid>

              {/* Botão de cadastro */}
              <Grid item xs={12}>
                <StyledButton onClick={handleSubmit}>
                  Cadastrar Jogo
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      {/* Feedback via Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default CadastrarJogoPage;

