import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

import api from "../../api";
import Header from "../../components/Header";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import InputText from "@mui/material/InputLabel";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  PageContainer,
  StyledCard,
  StyledButton,
  StyledDataGrid,
  DialogTitleStyled,
  DialogContentStyled,
  DialogActionsStyled,
} from "./Styles";
import FinancialUserDetails from "./FinancialUserDetails"; // Novo componente
import { color } from "chart.js/helpers";
import { Apartment } from "@mui/icons-material";
import PlayArrivedPage from "../arrived/PlayArrivedPage";
import ConfigurationManager from "../Configuration/ConfigurationManager";

const Aprove = () => {
  const { isAuthenticated } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);

  const [userSelect, setUserSelect] = useState("");
  const [perfil, setPerfil] = useState("");
  const [typePlayer, setTypePlayer] = useState("");
  const [nome, setNome] = useState("");
  const [nickName, setNickName] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [torre, setTorre] = useState("");
  const [apartamento, setApartamento] = useState("");
  const [status, setStatus] = useState("");
  const [isento, setIsento] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [financialDialogOpen, setFinancialDialogOpen] = useState(false);
  const [financialOperation, setFinancialOperation] = useState("");
  const [financialParams, setFinancialParams] = useState({});
  const [severity, setSeverity] = useState("success");
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([]);

  const [financialData, setFinancialData] = useState([]);
  const [balance, setBalance] = useState(0);

  const [defaulters, setDefaulters] = useState([]);
  const [previewMessage, setPreviewMessage] = useState("");
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  const getDefaulters = async () => {
    try {
      const response = await api.get("getDefaulter");
      if (response.data.success) {
        setDefaulters(response.data.players);
      } else {
        setMessage("Erro ao obter jogadores devedores");
        setSeverity("error");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Erro ao obter jogadores devedores:", error);
      setMessage("Erro ao obter jogadores devedores");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  const formatWhatsAppMessage = () => {
    const message = defaulters
      .filter((player) => player.balance < 0 || player.balance > 0)
      .map((player) => `${player.userName}: R$ ${player.balance.toFixed(2)}`)
      .join("\n");
    setPreviewMessage(`Rivierafc.com.br \n Jogadores em atraso:\n\n${message}`);
    setPreviewDialogOpen(true);
  };

  const handleShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      previewMessage
    )}`;
    window.open(whatsappUrl, "_blank");
    setPreviewDialogOpen(false);
  };

  const getUsers = async () => {
    try {
      const response = await api.get("user/showAll");
      const usersData = response.data.map((item) => {
        const { _id, ...resto } = item;
        return {
          id: _id,
          ...resto,
          typePlayer: item.typePlayer === "M" ? "Mensalista" : "Diarista",
          isento: item.isento ? "Sim" : "Não",
        };
      });
      setRows(usersData);
      setUsers(usersData);
    } catch (error) {
      console.error("Erro ao obter jogadores:", error);
      return [];
    }
  };

  const getFinancialData = async () => {
    try {
      const response = await api.get("/getBalance");
      setBalance(response.data.saldo);
      setFinancialData(
        response.data.records.map((record) => ({
          ...record,
          amount: record.type === "debit" ? -record.amount : record.amount,
        }))
      );
    } catch (error) {
      console.error("Erro ao obter dados financeiros:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  async function handleOpenUpdate(id) {
    const selectedUser = rows.find((row) => row.id === id);

    setUserSelect(selectedUser);
    setPerfil(selectedUser.perfil);
    setTypePlayer(selectedUser.typePlayer);
    setNome(selectedUser.nome);
    setNickName(selectedUser.nickName);
    setApartamento(selectedUser.apartamento);
    setTorre(selectedUser.torre);
    setStatus(selectedUser.status);
    setEmail(selectedUser.email);
    setIsento(selectedUser.isento === "Sim");
    setDialogOpen(true);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleCloseFinancialDialog = () => {
    setFinancialDialogOpen(false);
  };

  async function handleOpenApprove(id) {
    const selectedUser = rows.find((row) => row.id === id);
    setUserSelect(selectedUser);
    setConfirmDialogOpen(true);
  }

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmApproveUser = async () => {
    try {
      const body = { user_id: userSelect.id };
      const response = await api.post("user/aprove", body);
      if (response.status === 200) {
        setConfirmDialogOpen(false);
        setMessage("Usuário Aprovado.");
        setSeverity("success");
        setOpenMessage(true);
        getUsers();
      } else {
        setMessage(response.data.error);
        setSeverity("error");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Erro ao Aprovar", error);
      setMessage(error.response?.data?.error || "Ocorreu um erro");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  const updateUser = async () => {
    try {
      const body = {
        user_id: userSelect.id,
        perfil,
        typePlayer: typePlayer === "Mensalista" ? "M" : "D",
        isento,
        apartamento,
        email,
        status,
        torre,
        nome,
        nickName,
      };
      const response = await api.put("/updateForAdmin", body);
      if (response.data.success) {
        setMessage("Usuário atualizado com sucesso.");
        setSeverity("success");
        setOpenMessage(true);
        getUsers();
        handleClose();
      } else {
        setMessage(response.data.error);
        setSeverity("error");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Erro ao atualizar usuário:", error);
      setMessage("Erro ao atualizar usuário");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  const handleDeleteUser = (id) => {
    const selectedUser = rows.find((row) => row.id === id);
    setUserSelect(selectedUser);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDeleteUser = async () => {
    const body = {
      user_id: userSelect.id,
    };
    try {
      const response = await api.post(`/user/delete/`, body);
      if (response.status === 200) {
        setDeleteDialogOpen(false);
        setMessage("Usuário Excluído.");
        setSeverity("success");
        setOpenMessage(true);
        getUsers();
      } else {
        setMessage(response.data.error);
        setSeverity("error");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Erro ao Excluir", error);
      setMessage(error.response?.data?.error || "Ocorreu um erro");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  const handleOpenFinancialDialog = (operation) => {
    setFinancialOperation(operation);
    setFinancialDialogOpen(true);
  };

  const handleConfirmFinancialOperation = async () => {
    let url = "";
    let body = {};
    if (financialOperation === "debit") {
      url = "/createDebit";
      body = {
        description: financialParams.description,
        amount: financialParams.amount,
      };
    } else if (financialOperation === "revenue") {
      url = "/createRevenue";
      body = {
        user: financialParams.user.id,
        description: financialParams.description,
        amount: financialParams.amount,
      };
    } else if (financialOperation === "charge") {
      url = "/createCharge";
      body = {
        user: financialParams.user.id,
        description: financialParams.description,
        amount: financialParams.amount,
      };
    } else if (financialOperation === "credit") {
      url = "/createCredit";
      body = {
        description: financialParams.description,
        amount: financialParams.amount,
        type: "credit",
      };
    } else if (financialOperation === "createDebitMensalAll") {
      url = "/createDebitMensalAll";
    }

    try {
      const response = await api.post(url, body);
      if (response.status === 200 || response.data.success) {
        setFinancialDialogOpen(false);

        setMessage("Operação realizada com sucesso.");
        setSeverity("success");
        setOpenMessage(true);
        setTimeout(() => {
          console.log("Aguardou 3 segundos");
        }, 3000); // 3000 milissegundos = 3 segundos
        await getFinancialData();
      } else {
        setMessage(response.data.error);
        setSeverity("error");
        setOpenMessage(true);
      }
    } catch (error) {
      console.error("Erro ao realizar operação financeira", error);
      setMessage(error.response?.data?.error || "Ocorreu um erro");
      setSeverity("error");
      setOpenMessage(true);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 2, hide: true },
    { field: "nome", headerName: "Nome", width: 300 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "torre", headerName: "Torre", width: 100 },
    { field: "apartamento", headerName: "Apartamento", width: 130 },
    { field: "typePlayer", headerName: "Perfil Jogador", width: 130 },
    { field: "isento", headerName: "Isento Mensalidade", width: 130 },
    { field: "status", headerName: "Status", width: 90 },
    { field: "perfil", headerName: "Perfil", width: 90 },
    {
      field: "acoes",
      headerName: "Ações",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpenUpdate(params.row.id)}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => handleOpenApprove(params.row.id)}>
            <CheckCircleIcon color="action" />
          </IconButton>
          <IconButton onClick={() => handleDeleteUser(params.row.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const financialColumns = [
    { field: "description", headerName: "Descrição", width: 200 },
    {
      field: "amount",
      headerName: "Valor",
      width: 130,
      type: "number",
      renderCell: (params) => {
        const amount = params.row.amount;
        const formattedAmount = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(amount);
        const className = amount < 0 ? "negative" : "positive";
        return <span className={className}>{formattedAmount}</span>;
      },
    },
    { field: "userName", headerName: "Usuário", width: 200 },
    { field: "createdAt", headerName: "Data e Hora", width: 200 },
  ];

  useEffect(() => {
    getUsers();
    getFinancialData();
    getDefaulters();
  }, []);

  if (!isAuthenticated) {
    return (
      <PageContainer>
        <Header />
        <h2>Usuário não autenticado</h2>
      </PageContainer>
    );
  }

  return (
    <>
      <Header />
      <PageContainer>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          textColor="#fffff"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Administração de Usuários" sx={{ color: "#ffffff" }} />
          <Tab label="Financeiro" sx={{ color: "#ffffff" }} />
          <Tab label="Financeiro por Usuário" sx={{ color: "#ffffff" }} />
          <Tab label="Configurações" sx={{ color: "#ffffff" }} />
        </Tabs>

        {tabIndex === 0 && (
          <StyledCard>
            <h2>Administração de Usuários</h2>

            <StyledDataGrid
              rows={rows}
              columns={columns}
              autoHeight
              // disableColumnMenu
              pageSizeOptions={[10]}
              // checkboxSelection
              onRowClick={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              // onRowSelectionModelChange={(newRowSelectionModel) => {
              //   setRowSelectionModel(newRowSelectionModel);
              // }}
              // rowSelectionModel={rowSelectionModel}
            />
          </StyledCard>
        )}

        {tabIndex === 1 && (
          <StyledCard>
            <h2>Financeiro</h2>

            <Dialog
              open={previewDialogOpen}
              onClose={() => setPreviewDialogOpen(false)}
            >
              <DialogTitle>Preview da mensagem</DialogTitle>
              <DialogContent>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {previewMessage}
                </pre>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setPreviewDialogOpen(false)}>
                  Cancelar
                </Button>
                <Button
                  onClick={handleShare}
                  variant="contained"
                  color="primary"
                >
                  Compartilhar
                </Button>
              </DialogActions>
            </Dialog>

            {/* <Snackbar open={openMessage} onClose={() => setOpenMessage(false)} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <MuiAlert elevation={6} variant="filled" severity={severity} sx={{ width: '100%' }}>
                {message}
              </MuiAlert>
            </Snackbar> */}
            {balance < 0 ? (
              <h2 style={{ color: "red" }}>
                Saldo Atual: R$ {balance.toFixed(2)}
              </h2>
            ) : (
              <h2 style={{ color: "green" }}>
                Saldo Atual: R$ {balance.toFixed(2)}
              </h2>
            )}

            <StyledButton
              onClick={() => handleOpenFinancialDialog("credit")}
              variant="contained"
              color="primary"
            >
              Criar Crédito
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("debit")}
              variant="contained"
              color="primary"
            >
              Criar Débito
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("revenue")}
              variant="contained"
              color="primary"
            >
              Criar Receita
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("charge")}
              variant="contained"
              color="primary"
            >
              Criar Cobrança
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("createDebitMensalAll")}
              variant="contained"
              color="secondary"
            >
              Criar mensalidades
            </StyledButton>
            <StyledButton>
              <a
                href="#"
                onClick={formatWhatsAppMessage}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <img
                  src="https://tse2.mm.bing.net/th?id=OIP.1NvPud0z_PH9fd5S0sS-gAAAAA&pid=Api&P=0&h=180"
                  alt="WhatsApp"
                  style={{ marginRight: 8, height: 20 }}
                />
                Jogadores devedores
              </a>
            </StyledButton>
            <StyledDataGrid
              rows={financialData.map((item, index) => ({
                id: index,
                ...item,
              }))}
              columns={financialColumns}
              autoHeight
              disableColumnMenu
              pageSizeOptions={[10]}
            />
            <StyledButton
              onClick={() => handleOpenFinancialDialog("credit")}
              variant="contained"
              color="primary"
            >
              Criar Crédito
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("debit")}
              variant="contained"
              color="primary"
            >
              Criar Débito
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("revenue")}
              variant="contained"
              color="primary"
            >
              Criar Receita
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("charge")}
              variant="contained"
              color="primary"
            >
              Criar Cobrança
            </StyledButton>
          </StyledCard>
        )}

        {tabIndex === 2 && (
          <StyledCard>
            <h2>Financeiro por Usuário</h2>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("credit")}
              variant="contained"
              color="primary"
            >
              Criar Crédito
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("debit")}
              variant="contained"
              color="primary"
            >
              Criar Débito
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("revenue")}
              variant="contained"
              color="primary"
            >
              Criar Receita
            </StyledButton>
            <StyledButton
              onClick={() => handleOpenFinancialDialog("charge")}
              variant="contained"
              color="primary"
            >
              Criar Cobrança
            </StyledButton>
            <FinancialUserDetails />
          </StyledCard>
        )}

        {tabIndex === 3 && (
          <StyledCard>
            <h2>Configurações</h2>
            <ConfigurationManager />
          </StyledCard>
        )}

        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitleStyled>Atualizar Usuário</DialogTitleStyled>
          <DialogContentStyled>
            <InputLabel>Nome</InputLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              fullWidth
            />
            <InputLabel>Apelido</InputLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={nickName}
              onChange={(e) => setNickName(e.target.value)}
              fullWidth
            />
            <InputLabel>Email</InputLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <InputLabel>Torre</InputLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={torre}
              onChange={(e) => setTorre(e.target.value)}
              fullWidth
            />
            <InputLabel>Apartamento</InputLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={apartamento}
              onChange={(e) => setApartamento(e.target.value)}
              fullWidth
            />
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
            >
              <MenuItem value="Ativo">Ativo</MenuItem>
              <MenuItem value="Inativo">Inativo</MenuItem>
            </Select>
            <InputLabel>Perfil</InputLabel>
            <Select
              value={perfil}
              onChange={(e) => setPerfil(e.target.value)}
              fullWidth
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="User">Jogador</MenuItem>
              <MenuItem value="Director">Diretor</MenuItem>
            </Select>
            <InputLabel>Tipo de Jogador</InputLabel>
            <Select
              value={typePlayer}
              onChange={(e) => setTypePlayer(e.target.value)}
              fullWidth
            >
              <MenuItem value="Mensalista">Mensalista</MenuItem>
              <MenuItem value="Diarista">Diarista</MenuItem>
            </Select>
            <InputLabel>Isento</InputLabel>
            <Select
              value={isento}
              onChange={(e) => setIsento(e.target.value)}
              fullWidth
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </DialogContentStyled>
          <DialogActionsStyled>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={updateUser} color="primary">
              Salvar
            </Button>
          </DialogActionsStyled>
        </Dialog>

        <Dialog open={confirmDialogOpen} onClose={handleCloseConfirm}>
          <DialogTitleStyled>Confirmar Aprovação</DialogTitleStyled>
          <DialogContentStyled>
            <p>Tem certeza que deseja aprovar o usuário {userSelect.nome}?</p>
          </DialogContentStyled>
          <DialogActionsStyled>
            <Button onClick={handleCloseConfirm} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmApproveUser} color="primary">
              Aprovar
            </Button>
          </DialogActionsStyled>
        </Dialog>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitleStyled>Confirmar Exclusão</DialogTitleStyled>
          <DialogContentStyled>
            <p>Tem certeza de que deseja excluir {userSelect.nome}?</p>
          </DialogContentStyled>
          <DialogActionsStyled>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmDeleteUser} color="error">
              Excluir
            </Button>
          </DialogActionsStyled>
        </Dialog>

        <Dialog open={financialDialogOpen} onClose={handleCloseFinancialDialog}>
          <DialogTitleStyled>
            Operação Financeira {financialOperation}
          </DialogTitleStyled>
          <DialogContentStyled>
            {financialOperation !== "createDebitMensalAll" && (
              <>
                <InputLabel>Descrição</InputLabel>
                <TextField
                  value={financialParams.description || ""}
                  onChange={(e) =>
                    setFinancialParams({
                      ...financialParams,
                      description: e.target.value,
                    })
                  }
                  fullWidth
                />
                <InputLabel>Valor</InputLabel>
                <TextField
                  type="number"
                  value={financialParams.amount || ""}
                  onChange={(e) =>
                    setFinancialParams({
                      ...financialParams,
                      amount: e.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    },
                  }}
                />
              </>
            )}

            {(financialOperation === "revenue" ||
              financialOperation === "charge") && (
              <>
                <InputLabel>Usuário</InputLabel>
                <Autocomplete
                  options={users}
                  getOptionLabel={(option) => option.nome}
                  value={financialParams.user || null}
                  onChange={(event, newValue) =>
                    setFinancialParams({ ...financialParams, user: newValue })
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Usuário" variant="outlined" />
                  )}
                  fullWidth
                />
              </>
            )}

            {financialOperation === "createDebitMensalAll" && (
              <>
                <InputLabel>Mensalistas abaixo:</InputLabel>
                <ul>
                  {users
                    .filter((user) => user.typePlayer === "Mensalista") // Filtra os usuários que são do tipo 'M'
                    .map((filteredUser) => (
                      <li key={filteredUser.id}>{filteredUser.nome}</li> // Imprime o nome de cada usuário mensalista
                    ))}
                </ul>
              </>
            )}
          </DialogContentStyled>
          <DialogActionsStyled>
            <Button onClick={handleCloseFinancialDialog} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmFinancialOperation} color="primary">
              Confirmar
            </Button>
          </DialogActionsStyled>
        </Dialog>

        <Snackbar
          open={openMessage}
          autoHideDuration={6000}
          onClose={() => setOpenMessage(false)}
        >
          <MuiAlert
            onClose={() => setOpenMessage(false)}
            severity={severity}
            elevation={50}
            variant="filled"
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </PageContainer>
    </>
  );
};

export default Aprove;
