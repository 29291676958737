import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import { Grid, Button, Card, CardContent, MenuItem, TextField, InputLabel, Select, FormControl } from "@mui/material";


export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const StyledCard = styled.div`
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 90%;
  margin-top: 20px;
  text-align: center;
`;

export const StyledButton = styled.button`
  background-color: #1976d2;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    background-color: #115293;
  }
`;

export const PlayerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    cursor: pointer;
  }
`;

export const PlayerImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px;
`;

export const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    padding: 20px;
    background-color: #fff;
  }
`;

export const DialogTitle = styled.h2`
  margin-bottom: 10px;
`;

export const DialogActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

export const DialogButton = styled.button`
  background-color: #1976d2;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    background-color: #115293;
  }
`;

export const BackButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;
