import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer, CardContainer, StyledImageCard, TournamentTitle } from "./styleAdminJogosPage";
import Header from "../../components/Header";
import api from "../../api";

// Imagens representando as ações
// import NovoJogoImg from "../imagens/capa_rfc.png";
// import JogosImg from "../../assets/jogos.png";
// import ClassificacaoImg from "../../assets/classificacao.png";
// import ArtilhariaImg from "../../assets/artilharia.png";

const AdminJogosPage = () => {
  const [torneio, setTorneio] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTorneio = async () => {
      try {
        const response = await api.get("/getTorneioActive"); // Supondo que essa rota retorna o torneio ativo
        setTorneio(response.data.nome); // Define o nome do torneio
      } catch (error) {
        console.error("Erro ao buscar torneio ativo:", error);
      }
    };

    fetchTorneio();
  }, []);

  const handleNovoJogo = () => {
    navigate("/cadastrar-jogo");
  };

  const handleVerJogos = () => {
    navigate("/listar-jogos");
  };

  const handleClassificao = () => {
    navigate("/classificacao");
  };

  const handleClassificaoJogadores = () => {
    navigate("/classificacao/jogadores");
  };

  return (
    <PageContainer>
      <Header />
      {torneio && <TournamentTitle>{torneio}</TournamentTitle>}
      <CardContainer>
        <StyledImageCard onClick={handleNovoJogo}>
          <img src={'/image/novoJogo.png'} alt="Cadastrar Novo Jogo" />
          <h3>Cadastrar Novo Jogo</h3>
        </StyledImageCard>

        <StyledImageCard onClick={handleVerJogos}>
          <img src={'/taca_campeao.png'}  alt="Ver Jogos" />
          <h3>Jogos</h3>
        </StyledImageCard>

        <StyledImageCard onClick={handleClassificao}>
          <img src={'/image/classificacao.png'}  alt="Classificação" />
          <h3>Classificação/Grupos</h3>
        </StyledImageCard>

        <StyledImageCard onClick={handleClassificaoJogadores}>
          <img src={'/image/artilheiro.png'}  alt="Artilharia/Fair Play" />
          <h3>Artilharia / Fair Play</h3>
        </StyledImageCard>
      </CardContainer>
    </PageContainer>
  );
};

export default AdminJogosPage;
