import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container, Button, TextField, Card, CardContent, CircularProgress, Grid, Dialog, DialogTitle, DialogContent, DialogActions,
} from "@mui/material";
import styled from "styled-components";
import api from "../../../api";

// Estilos personalizados
const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const PlayerCard = styled.div`
  border: 2px solid ${({ selected }) => (selected ? "lightgreen" : "#ccc")};
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: ${({ selected }) => (selected ? "lightgreen" : "white")};
`;

const PlayerImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const TeamImage = styled.img`
  height: 50px;
  width: 50px;
  margin-top: 5px;
`;

const Header = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #1976d2;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VotarMelhorJogadorPage = () => {
  const [apartamento, setApartamento] = useState("");
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [jogadores, setJogadores] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVoting, setIsVoting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJogadores = async () => {
      setIsLoading(true);
      try {
        const response = await api.post("/report/getTimes", {
          torneioId: "66b845dc683edac2419d0888",
        });
        const jogadoresArray = [];
        Object.entries(response.data).forEach(([teamName, team]) => {
          team.jogadores.forEach((jogador) => {
            jogadoresArray.push({
              ...jogador,
              teamName: teamName, // Usando a chave como nome do time
            });
          });
        });
        setJogadores(jogadoresArray);
      } catch (error) {
        console.error("Erro ao buscar jogadores:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJogadores();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVoting(true);
    try {
      await api.post("/votar", {
        apartamento,
        email,
        nome,
        jogadorId: selectedPlayer._id,
      });

      setSuccessMessage("Voto registrado com sucesso!");
      setErrorMessage("");
      setIsError(false);
      setDialogOpen(true);
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.error);
      setSuccessMessage("");
      setIsError(true);
      setDialogOpen(true);
    } finally {
      setIsVoting(false);
    }
  };

  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    if (!isError) {
      navigate("/");
    }
  };

  return (
    <PageContainer maxWidth="md">
      <Header>Votação para Melhor Jogador</Header>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Apartamento"
              value={apartamento}
              onChange={(e) => setApartamento(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nome Completo"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
              fullWidth
              margin="normal"
            />

            <h3>Selecione o Jogador:</h3>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={2}>
                {jogadores.map((jogador) => (
                  <Grid item xs={6} sm={4} md={3} key={jogador._id}>
                    <PlayerCard
                      selected={selectedPlayer && selectedPlayer._id === jogador._id}
                      onClick={() => handlePlayerSelect(jogador)}
                    >
                      <PlayerImage
                        src={`/players/${jogador.nome.split(" ")[0].replace(/ /g, "_")}.jpg`}
                        alt={jogador.nome}
                      />
                      <span>{jogador.nome}</span>
                      <span>Camisa: {jogador.numeroCamisa}</span>
                      <TeamImage
                        src={`/times/${jogador.teamName.replace(/ /g, "_")}.png`}
                        alt={jogador.teamName}
                      />
                    </PlayerCard>
                  </Grid>
                ))}
              </Grid>
            )}

            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isVoting || !selectedPlayer}
              style={{ marginTop: "20px" }}
            >
              {isVoting ? <CircularProgress size={24} /> : "Votar"}
            </StyledButton>
          </form>
        </CardContent>
      </Card>

      {isVoting && <Overlay><CircularProgress /></Overlay>}

      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>{isError ? "Erro" : "Sucesso"}</DialogTitle>
        <DialogContent>
          {isError ? (
            <p style={{ color: "red" }}>{errorMessage}</p>
          ) : (
            <>
              <p style={{ color: "green" }}>{successMessage}</p>
              {selectedPlayer && (
                <PlayerImage
                  src={`/players/${selectedPlayer.nome.split(" ")[0].replace(/ /g, "_")}.jpg`}
                  alt={selectedPlayer.nome}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default VotarMelhorJogadorPage;
