import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  margin-top: 40px;
  width: 100%;
  max-width: 900px;
`;

export const StyledImageCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
`;

export const TournamentTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
`;
