import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import api from "../../../api";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Estilos personalizados
const Header = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #1976d2;
`;

const TeamImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 10px;
`;

const GameCard = styled(Card)`
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GameInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ScoreInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const BackButton = styled(Button)`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const ClassificacaoPage = () => {
  const [classificacao, setClassificacao] = useState({ A: [], B: [] });
  const [semifinais, setSemifinais] = useState([]);
  const [finais, setFinais] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClassificacao = async () => {
      try {
        const response = await api.get("/classificacao/grupos");
        const { classificacao, semifinais, finais } = response.data;
        setClassificacao(classificacao);
        setSemifinais(semifinais);
        setFinais(finais);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar a classificação:", error);
        setIsLoading(false);
      }
    };

    fetchClassificacao();
  }, []);

  const renderClassificacao = (grupo, nomeGrupo) => (
    <Container>
      <h3>Classificação Grupo {nomeGrupo}</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Pts</TableCell>
            <TableCell>Vit</TableCell>
            <TableCell>Emp</TableCell>
            <TableCell>Der</TableCell>
            <TableCell>Saldo Gols</TableCell>
            <TableCell>Gols P</TableCell>
            <TableCell>Gols C</TableCell>
            <TableCell>Amar</TableCell>
            <TableCell>Azul</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grupo.map((time, index) => (
            <TableRow key={time.nome}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <TeamImage
                  src={`/times/${time.nome.replace(/ /g, "_")}.png`}
                  alt={time.nome}
                />
                {time.nome}
              </TableCell>
              <TableCell>{time.pontos}</TableCell>
              <TableCell>{time.vitorias}</TableCell>
              <TableCell>{time.empates}</TableCell>
              <TableCell>{time.derrotas}</TableCell>
              <TableCell
                style={{ color: time.saldoGols > 0 ? "green" : "red" }}
              >
                {time.saldoGols}
              </TableCell>
              <TableCell>{time.golsPro}</TableCell>
              <TableCell>{time.golsContra}</TableCell>
              <TableCell>{time.cartoesAmarelos}</TableCell>
              <TableCell>{time.cartoesAzuis}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );

  const renderGameDetails = (game) => (
    <Details>
      <div>
        <strong>Gols:</strong>{" "}
        {game.gols.map((gol) => (
          <span key={gol.jogador._id}>
            {gol.jogador.nome}: {gol.quantidade} gol(s)
          </span>
        ))}
      </div>
      <div>
        <strong>Cartões:</strong>{" "}
        {game.cartoes.map((cartao) => (
          <span key={cartao.jogador._id}>
            {cartao.jogador.nome} - {cartao.tipo}
          </span>
        ))}
      </div>
      <div>
        <strong>Assistências:</strong>{" "}
        {game.assistencias.map((assist) => (
          <span key={assist.jogador._id}>
            {assist.jogador.nome}: {assist.quantidade} assistência(s)
          </span>
        ))}
      </div>
    </Details>
  );

  const renderGames = (title, games) => (
    <>
      <h3>{title}</h3>
      <Grid container spacing={2}>
        {games.map((game, index) => (
          <Grid item xs={12} key={index}>
            <GameCard>
              <GameInfo>
                <div>
                  <TeamImage
                    src={`/times/${game.timeA.nome.replace(/ /g, "_")}.png`}
                    alt={game.timeA.nome}
                  />
                  <span>{game.timeA.nome}</span>
                </div>
                <ScoreInfo>
                  {game.placar.timeA} x {game.placar.timeB}
                </ScoreInfo>
                <div>
                  <TeamImage
                    src={`/times/${game.timeB.nome.replace(/ /g, "_")}.png`}
                    alt={game.timeB.nome}
                  />
                  <span>{game.timeB.nome}</span>
                </div>
              </GameInfo>
              {renderGameDetails(game)}
            </GameCard>
          </Grid>
        ))}
      </Grid>
    </>
  );

  return (
    <Container>
         <BackButton onClick={() => navigate("/torneio")}>
        <ArrowBackIcon /> Voltar
      </BackButton>
      <Header>Classificação do Torneio</Header>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {renderClassificacao(classificacao.A, "A")}
          {renderClassificacao(classificacao.B, "B")}

          {/* Renderizar as Semifinais */}
          {semifinais.length > 0 ? (
            renderGames("Semifinais", semifinais)
          ) : (
            <Typography variant="h6" align="center">
              Semifinais ainda não cadastradas.
            </Typography>
          )}

          {/* Renderizar as Finais */}
          {finais.length > 0 ? (
            renderGames("Final", finais)
          ) : (
            <Typography variant="h6" align="center">
              Final ainda não cadastrada.
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default ClassificacaoPage;
