import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import ReportIcon from "@mui/icons-material/Report";
import api from "../../../api";

// Estilos personalizados
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
`;
const Pageh2 = styled.h2`
 color:black
`;


const StyledCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
`;

const BackButton = styled(Button)`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #1976d2;
  color: white;
  &:hover {
    background-color: #155a9f;
  }
`;

const PlayerInfo = styled(Grid)`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: display;
    align-items: flex-start;
  }
`;

const PlayerImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 10px;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const PlayerStats = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StatIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${({ color }) => color || "black"};
`;

const PlayerName = styled.h4`
  font-size: 1.2em;
  margin-right: 10px;
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Legend = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
  font-size: 14px;
  color: #555;
`;

const ClassificacaoArtilhariaPage = () => {
  const [ranking, setRanking] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClassificacao = async () => {
      try {
        const response = await api.get("/classificacao/jogadores");
        setRanking(response.data.ranking);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar classificação:", error);
        setLoading(false);
      }
    };

    fetchClassificacao();
  }, []);

  const renderPlayerRanking = (player, index) => (
    <StyledCard key={index}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {/* Posicionamento do jogador */}
          <Grid item xs={2} sm={1}>
            <strong>{index + 1}</strong>
          </Grid>

          {/* Nome do jogador e imagem */}
          <Grid item xs={10} sm={3}>
            <PlayerInfo>
              <PlayerImage
                src={
                  player.jogador === "THIAGO NUNES"
                    ? `/players/${player.jogador.replace(/ /g, "_")}.jpg`
                    : `/players/${player.jogador.split(" ")[0].replace(/ /g, "_")}.jpg`
                }
                alt={player.jogador}
              />
              <br></br>
              <PlayerName>{player.jogador}</PlayerName>
            </PlayerInfo>
          </Grid>

          {/* Estatísticas */}
          <Grid item xs={12} sm={8}>
            <PlayerStats>
              <StatIcon color="green">
                <SportsSoccerIcon />
                {player.gols}
              </StatIcon>
              <StatIcon color="blue">
                <AssistantPhotoIcon />
                {player.assistencias}
              </StatIcon>
              <StatIcon color="red">
                <ReportIcon />
                {player.cartoes}
              </StatIcon>
            </PlayerStats>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );

  return (
    <PageContainer>
      <BackButton onClick={() => navigate("/torneio")}>
        <ArrowBackIcon /> Voltar
      </BackButton>

      <Pageh2>Classificação de Artilharia</Pageh2>

      {/* Legenda dos ícones */}
      <Legend>
        <span>
          <SportsSoccerIcon style={{ color: "green" }} /> Gols
        </span>
        <span>
          <AssistantPhotoIcon style={{ color: "blue" }} /> Assistências
        </span>
        <span>
          <ReportIcon style={{ color: "red" }} /> Cartões
        </span>
      </Legend>

      {loading ? (
        <CircularProgress />
      ) : (
        ranking.map((player, index) => renderPlayerRanking(player, index))
      )}
    </PageContainer>
  );
};

export default ClassificacaoArtilhariaPage;
