// MelhorJogadorPage.js
import React, { useState, useEffect } from 'react';
import { Container, Card, CardContent, Typography, CircularProgress, Grid, Avatar } from '@mui/material';
import styled from 'styled-components';
import api from '../../../api';

const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
`;

const PlayerCard = styled(Card)`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  text-align: center;
`;

const VoteCard = styled(Card)`
  margin-top: 10px;
  padding: 10px;
`;

const StyledAvatar = styled(Avatar)`
  height: 150px;
  width: 150px;
  margin: 20px auto;
`;

const Header = styled(Typography)`
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #1976d2;
`;

const MelhorJogadorPage = () => {
  const [melhoresJogadores, setMelhoresJogadores] = useState([]);
  const [votos, setVotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMelhorJogador = async () => {
      try {
        const response = await api.get('/melhor-jogador');
        setMelhoresJogadores(response.data.melhoresJogadores);
        setVotos(response.data.votos);
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar o melhor jogador:', error);
        setIsLoading(false);
      }
    };

    fetchMelhorJogador();
  }, []);

  if (isLoading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer maxWidth="md">
      <Header>Melhor Jogador do Torneio</Header>

      <Grid container spacing={2}>
        {melhoresJogadores.map((jogador, index) => (
          <Grid item xs={12} md={6} key={index}>
            <PlayerCard>
              <CardContent>
                <StyledAvatar alt={jogador.nome} src={jogador.foto} />
                <Typography variant="h5" component="div">
                  {jogador.nome}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {jogador.votos} votos
                </Typography>
              </CardContent>
            </PlayerCard>
          </Grid>
        ))}
      </Grid>

      <Header>Lista de Votos</Header>

      <Grid container spacing={2}>
        {votos.map((voto, index) => (
          <Grid item xs={12} md={6} key={index}>
            <VoteCard>
              <Typography variant="h6">Nome: {voto.nome}</Typography>
              <Typography variant="body1">Email: {voto.email}</Typography>
              <Typography variant="body1">Apartamento: {voto.apartamento}</Typography>
              <Typography variant="body1">voto: {voto.jogador}</Typography>
            </VoteCard>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
};

export default MelhorJogadorPage;
