import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlayerCard from "../../components/PlayerCard/PlayerCard";
import api from "../../api";
import {
  TimeContainer,
  Time,
  TimeTitle,
  TimeJogadores,
  TimeImage,
} from "./style";
import Header from "../../components/Header";
import { colors } from "@mui/material";
import Button from "@mui/material/Button";

const CACHE_KEY = "timesCache";
const CACHE_EXPIRATION = 1 * 24 * 60 * 60 * 1000; // 3 dias em milissegundos

const TimesPage = () => {
  const navigate = useNavigate();
  const [times, setTimes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verifique se há um cache existente e se ele ainda é válido
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY}_timestamp`);

    if (cachedData && cachedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(cachedTimestamp, 10) <= CACHE_EXPIRATION) {
        // Cache válido, use os dados em cache
        setTimes(JSON.parse(cachedData));
        setLoading(false);
        return;
      }
    }

    // Se não houver cache válido, faça uma solicitação à API
    api
      .post("/report/getTimes", { torneioId: "66b845dc683edac2419d0888" })
      .then((response) => {
        const timesData = [];
        for (const nomeTime in response.data) {
          timesData.push({
            nomeTime: nomeTime,
            jogadores: response.data[nomeTime].jogadores,
            score: response.data[nomeTime].score,
          });
        }
        timesData.sort((a, b) => b.score - a.score);
        setTimes(timesData);
        setLoading(false);

        // Armazene os dados no cache
        localStorage.setItem(CACHE_KEY, JSON.stringify(timesData));
        localStorage.setItem(
          `${CACHE_KEY}_timestamp`,
          new Date().getTime().toString()
        );
      })
      .catch((error) => {
        console.error("Erro ao obter times:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <p>Carregando times...</p>
      ) : (
        <TimeContainer>
          <TimeTitle>
            <h2 style={{ color: "red" }}>6º Torneio RFC 2024 </h2>
            <Button variant="contained" onClick={() => navigate("/jogos")}>
              Jogos
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate("/classificacaoView")}
            >
              Classificação
            </Button>
            <Button variant="contained" onClick={() => navigate("/artilheiro")}>
              Artilheiro
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate("/melhorJogador")}
            >
              Melhor Jogador
            </Button>
          </TimeTitle>
          {times.map((time) => (
            <Time key={time.nomeTime}>
              <TimeTitle>
              <TimeImage
                src={`/times/${time.nomeTime.replace(/ /g, "_")}.png`}
                alt={"Time"}
              />

{time.nomeTime === "Manchester City" && (
                <TimeTitle>
                  <TimeImage
                    src={`/taca_campeao.png`}
                    alt={"Time"}
                  />
                   <p>Campeão 2024</p>
                </TimeTitle>
              )}

              </TimeTitle>
              <TimeTitle>
                {time.nomeTime}
                <p>Força: {time.score.toFixed(2)}</p>

              
              </TimeTitle>
            
              <TimeJogadores>
                {time.jogadores.map((jogador) => (
                  <PlayerCard
                    key={jogador._id}
                    playerName={jogador.nome}
                    tower={jogador.user != null ? jogador.user.torre : ""}
                    // apartment={jogador.user.apartamento}
                    ranking={jogador.ranking}
                    pote={jogador.pote}
                    playerImage={
                      jogador.nome == "THIAGO NUNES"
                        ? `players/${jogador.nome.replace(/ /g, "_")}.jpg`
                        : `/players/${jogador.nome
                            .split(" ")[0]
                            .replace(/ /g, "_")}.jpg`
                    }
                    timeImage={`/times/${time.nomeTime.replace(/ /g, "_")}.png`}
                    paisImage={`/bandeira_${jogador.pais}.png`}
                  ></PlayerCard>
                ))}
              </TimeJogadores>
            </Time>
          ))}
        </TimeContainer>
      )}
    </div>
  );
};

export default TimesPage;
